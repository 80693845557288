<script lang="ts">
  import { fade } from 'svelte/transition';

  export let expand: boolean = false;
  export let inDuration: number = 300;
  export let outDuration: number = 300;
</script>

{#if expand}
  <div in:fade={{ duration: inDuration }} out:fade={{ duration: outDuration }}>
    <slot />
  </div>
{/if}
