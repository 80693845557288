import { divmod } from "./number_utils";
import type { TypeCalendarState } from "./types";

export const dateToStr = (dateTime: Date) => {
    const yearStr = dateTime.getFullYear().toString().padStart(4, '0');
    const monthStr = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const dateStr = dateTime.getDate().toString().padStart(2, '0');
    const hourStr = dateTime.getHours().toString().padStart(2, '0');
    const minuteStr = dateTime.getMinutes().toString().padStart(2, '0');
    const UTCyearStr = dateTime.getUTCFullYear().toString();
    const UTCmonthStr = (dateTime.getUTCMonth() + 1).toString().padStart(2, '0');
    const UTCdateStr = dateTime.getUTCDate().toString().padStart(2, '0');
    const UTChourStr = dateTime.getUTCHours().toString().padStart(2, '0');
    const UTCminuteStr = dateTime.getUTCMinutes().toString().padStart(2, '0');
    const weekdayStr = dateTime.toLocaleString('ja-JP', { weekday: 'short' });
    const secondStr = dateTime.getSeconds().toString().padStart(2, '0');
    const YMDformat = `${yearStr}-${monthStr}-${dateStr}`;
    const YMDJPformat = `${yearStr}年${monthStr}月${dateStr}日`;
    const MDJPformat = `${monthStr}月${dateStr}日 (${weekdayStr})`;
    const MDhmformat = `${monthStr}月${dateStr}日 ${hourStr}:${minuteStr}`;
    const YMDhmJPformat = `${yearStr}年${monthStr}月${dateStr}日 ${hourStr}:${minuteStr}`;
    const hmformat = `${hourStr}:${minuteStr}`;
    const YMDhmsFormat = `${yearStr}-${monthStr}-${dateStr}T${hourStr}:${minuteStr}:${secondStr}Z`;
    const dateTimeInputFormat = `${yearStr}-${monthStr}-${dateStr}T${hourStr}:${minuteStr}:${secondStr}`;
    const iso8601Format = `${yearStr}-${monthStr}-${dateStr}T${hourStr}:${minuteStr}+09:00`;
    const UTCYMDhmsFormat = `${UTCyearStr}${UTCmonthStr}${UTCdateStr}T${UTChourStr}${UTCminuteStr}${secondStr}Z`;
    return {
        YMDformat,
        YMDJPformat,
        MDJPformat,
        MDhmformat,
        hmformat,
        YMDhmsFormat,
        YMDhmJPformat,
        dateTimeInputFormat,
        iso8601Format,
        UTCYMDhmsFormat,
    };
};

/** 入力された時間（UTC）をJSTに変換する。getTimezoneOffset は-540を返す。
 実行環境に左右されずにJSTを取得したい場合に使用する
*/
export const getJst = (date: number) => {
    return new Date(date + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000);
};

/** ある日付から指定した日付分経過もしくは遡ったDateオブジェクトを返す */
export const getDateFromValue = (start: Date, reverse: boolean, days = 0, hours = 0, minutes = 0, seconds = 0) => {
    const hoursFromDays = days * 24
    const minutesFromHours = (hoursFromDays + hours) * 60
    const secondsFromMinutes = (minutesFromHours + minutes) * 60
    let microsecFromSeconds = (secondsFromMinutes + seconds) * 1000
    if (reverse) microsecFromSeconds = microsecFromSeconds * -1
    return new Date(start.getTime() + microsecFromSeconds)
}

/** startTime とendTime の時間差をstringで表す */
export const getTimeDurationString = (startTime: Date, endTime: Date) => {
    const dulation = endTime.getTime() - startTime.getTime();
    const dulationMinute = dulation / 60000;
    const hour = Math.floor(dulationMinute / 60);
    const minute = Math.floor(dulationMinute % 60);
    return `${hour.toString()}:${minute.toString().padStart(2, '0')}`;
};

export const dateNumToDateStr = (dateNum: number) => {
    const dateStrArr = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    return dateStrArr[dateNum];
};


export const getZeroOclock = (date: Date) => {
    /** 入力されたDateの0時0分0秒を返す */
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

/** event.extendedProps.rruleString からdtstartの文字列を抽出し、Date形式で返す。 */
export const makeDateFromDtsr = (rruleString: string) => {
    let dtstr = '';
    if (rruleString.includes('DTSTART')) {
        const rulesplit = rruleString.split('\n');
        dtstr = rulesplit[0].replace('DTSTART:', '');
    } else {
        dtstr = rruleString;
    }

    const date = new Date(
        Number(dtstr.substring(0, 4)), // year
        Number(dtstr.substring(4, 6)) - 1, // month
        Number(dtstr.substring(6, 8)) + 1, // date
        Number(dtstr.substring(9, 11)), // hour
        Number(dtstr.substring(11, 13)), // minute
    );
    return date;
};

/** baseDateを基準として、back, forwardで指定した月数分の期間を取得する。
 * デフォルトでは指定日付（月初日）の1ヶ月前から3ヶ月後まで
 * カレンダーの表記に従い、startは日曜日で始まり、endは土曜日で終わるように補正する
 */
export const makeNewReferencePeriod = (baseDate: Date, back = 1, forward = 3) => {
    //baseDateを月初日に変換
    const adjustedBase = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1)
    const baseYear = adjustedBase.getFullYear()
    // 参照期間の月初、月末日を取得
    const [startYearDif, startMonth] = divmod(12 + adjustedBase.getMonth() - back, 12)
    const [endYearDif, endMonth] = divmod(adjustedBase.getMonth() + forward, 12)
    const startDate = new Date(baseYear + (startYearDif - 1), startMonth, 1)
    const endDate = new Date(baseYear + endYearDif, endMonth, 1)
    // カレンダー表記に合わせる
    const startDateSunday = getDateFromValue(startDate, true, startDate.getDay())
    const endDateSunday = getDateFromValue(endDate, true, endDate.getDay())
    const endDateSaturday = getDateFromValue(endDateSunday, false, 42)
    const newPeriod = { start: startDateSunday, end: endDateSaturday }
    // console.log(newPeriod)
    return newPeriod
}

/** 指定した年、月の月初と月末日を取得 */
export const getFirstAndLastDateOfMOnth = (year: number, month: number) => {
    const firstDate = new Date(year, month, 1)
    const [endYearDif, endMonth] = divmod(month + 1, 12)
    const lastDate = new Date(new Date(year + endYearDif, endMonth, 1))
    return { first: firstDate, last: lastDate }
}

/** 指定した年、月の月初と月末日を取得 */
export const getFirstDateOfMonth = (baseDate: Date) => {
    return new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        1
    )
}

/** 参照年月を指定日付を基準に作成
 * デフォルトでは指定日の１ヶ月前から３ヶ月後までを設定する 
*/
export const setCalendarState = (baseDate: Date, back = 1, forward = 3): TypeCalendarState => {
    const refPeriod = makeNewReferencePeriod(baseDate, back, forward)
    return {
        start: refPeriod.start,
        end: refPeriod.end,
        currentYear: baseDate.getFullYear(),
        currentMonth: baseDate.getMonth()
    }
}

/** 参照年月を現在を基準に作成 */
export const initCalendarState = () => {
    const now = new Date()
    return setCalendarState(now)
}


/** 入力した日付からmonthDif分後もしくは前の日付を取得
 * monthDif > 0の場合は後の日付、monthDif < 0の場合は前の日付を返す
 */
export const getDateAfterMonth = (date: Date, monthDif: number) => {
    const numOfMonth = date.getFullYear() * 12 + date.getMonth()
    const [newYear, newMonth] = divmod(numOfMonth + monthDif, 12)
    return new Date(newYear, newMonth, date.getDate());
}

export const isSameDay = (baseDate: Date, targetDate: Date) => {
    return (
        baseDate.getFullYear() === targetDate.getFullYear() &&
        baseDate.getMonth() === targetDate.getMonth() &&
        baseDate.getDate() === targetDate.getDate()
    );
}

export const isSameMonth = (baseDate: Date, targetDate: Date) => {
    return (
        baseDate.getFullYear() === targetDate.getFullYear() &&
        baseDate.getMonth() === targetDate.getMonth()
    );
}