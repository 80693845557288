<script lang="ts">
  export let display = false;
  let src = '/static/img/oval.svg';
</script>

{#if display}
  <div class="loading-spinner d-flex">
    <img class="spin-circle" {src} alt="spinner" />
  </div>
{/if}

<style>
  .loading-spinner {
    display: flex !important;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: table;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.6;
  }
  .spin-circle {
    max-width: 3rem;
    opacity: 1;
  }
</style>
