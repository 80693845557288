<script lang="ts">
  import Switch from '@smui/switch';
  import FormField from '@smui/form-field';

  export let checked = false;
  export let label = 'toggle';
</script>

<div>
  <FormField>
    <Switch bind:checked icons={false} />
    <span slot="label">{label}</span>
  </FormField>
</div>
